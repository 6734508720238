.tree-nav {
  $nav-vertical-padding:  5px;
  $nav-border-size:       2px;

  position: relative;
  padding: $nav-vertical-padding 0;
  border: $nav-border-size solid $tree-nav-border-color;
  text-align: left;
  background: $base-second-link-color;
  box-shadow: 0 3px 4px $tree-nav-shadow-color;

  &__item {
    position: relative;
    padding: 0 5px;

    &--top-drop {
      position: static;
    }
  }

  &__link {
    display: block;
    white-space: nowrap;
    position: relative;
    padding: 5px 30px 5px 10px;
    color: $page-mobile-color;
    background: $base-second-link-color;
    text-decoration: none;
  }
  &__item:hover > &__link{
    background: $theme-third-color;
    color: $tree-nav-link-hover-color;
  }


  &__ico {
    float: left;
    margin-top: -1px;
    margin-right: 4px;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    top: 4px;
    display: inline-block;
    fill: $tree-nav-link-color;

    &--right{
      width: 4px;
      height: 7px;
    }
  }
  &__item:hover > &__link &__arrow{
    fill: $tree-nav-link-hover-color;
  }

  &__drop {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    top: 0;
    left: 100%;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 100%;
      left: auto;
    }
  }
  &__item:hover > &__drop{
    visibility: visible;
    opacity: 1;
    margin-top: -($nav-vertical-padding + $nav-border-size);
  }

}