.footer {
  color: $footer-text-color;

  &__title {
    font-size: 1.5rem;
    color: $white;
  }

  &__inner {
    margin-top: 10px;
  }

  &__items {

  }

  &__item {
    margin-top: 5px;
    color: $base-main-link-hover-color;
  }

  &__link {
    color: $official-haibike-color;
    text-decoration: $base-main-link-decor;

    &:hover {
      color: $base-main-link-hover-color;
    }
  }

}