.product-fullinfo{

	&__item{
		&:not(:last-child){
			margin-bottom: 40px;
		}
	}

	&__header{
		padding: 15px 25px;
		margin-bottom: 1.2rem;
		background: $theme-secondary-color;
	}

	&__title{
		font-size: 1.2rem;
		font-weight: bold;
		line-height: 1;
		color: $widget-primary-title-color;
	}	

}