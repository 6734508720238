/*
--------------------------------------------------
Navigation table-navs styles
--------------------------------------------------
*/
.table-nav {
  $z-intdex: $z-index-s;

  position: relative;

  //Modifiers
  &--equal {
    .table-nav__items {
      table-layout: fixed;
    }
  }

  //Elements
  &__items {
    display: table;
    width: 100%;
    height: 50px;
  }

  &__item {
    position: relative;
    display: table-cell;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    background-color: $base-second-link-color;
    border-left: 1px solid $base-second-link-color;

    &:first-child {
      border-left: 0;
    }

    &:hover {
      .table-nav__link {
        background: $table-nav-bg-hover-color;
        color: $table-nav-link-hover-color;
      }
    }

    //Element Modifiers
    &--active {

      .table-nav__link {
        background-color: $table-nav-bg-active-color;
      }
    }
  }

  &__link {
    font-size: 0;
    display: block;
    height: 100%;
    color: $table-nav-link-color;
    text-decoration: none;

    &:before {
      content: '';
      height: 100%;
      padding: 10px 0;
      display: inline-block;
      vertical-align: middle;
    }

    &-helper {
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
      font-size: 1rem;
    }
  }

  &__drop {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 0;
      left: auto;
    }
  }
  &__item:hover > &__drop{
    visibility: visible;
    opacity: 1;
    width: 100%;
  }

}