/*
--------------------------------------------------
Reset and normalize default browser styles
--------------------------------------------------
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input, select, textarea {
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
}

/* Handle box-sizing while better addressing child elements */
html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
  /* force a vertical scrollbar to prevent a jumpy page */
  overflow-y: scroll;
}

html, body {
  min-height: 100%;
  /* disable webkit tap highlight */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img,
fieldset,
hr,
legend {
  border: 0;
}

object,
video,
embed,
img {
  max-width: 100%;
  height: auto;
}

article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

audio,
canvas,
video,
figure {
  display: inline-block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

tr {
  page-break-inside: avoid;
}

blockquote {
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

[hidden] {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

/* Form normalize BEGIN */
button,
input,
select,
textarea {
  box-shadow: none;
  background-color: transparent;
  border: 0;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}